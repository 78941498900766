import gql from "graphql-tag";

const RELEASE_FRAGMENT = gql`
  fragment release on ReleaseNote {
    id
    url
    version
    summary
    details
    createdAt
  }
`;

export const RELEASE_NOTES_QUERY = gql`
  query RELEASE_NOTES_QUERY($product: String!) {
    releaseNotes(product: $product) {
      ...release
    }
  }
  ${RELEASE_FRAGMENT}
`;

export const SOFTWARE_UPDATES_QUERY = gql`
  query SOFTWARE_UPDATES_QUERY($clientVersion: String!, $product: String!) {
    releaseNotes: softwareUpdateStatus(
      clientVersion: $clientVersion
      product: $product
    ) {
      current {
        ...release
      }
      upgrades {
        ...release
      }
      downgrades {
        ...release
      }
    }
  }
  ${RELEASE_FRAGMENT}
`;

export const RELEASE_NOTE_QUERY = gql`
  query SEND_FEEDBACK_QUERY($id: ID!) {
    release(id: $id) {
      ...release
    }
  }
  ${RELEASE_FRAGMENT}
`;

export const RELEASE_SEND_FEEDBACK_MUTATION = gql`
  mutation RELEASE_SEND_FEEDBACK($input: SendFeedbackInput!) {
    mutationResult: releaseSendFeedback(input: $input) {
      errors
    }
  }
`;
