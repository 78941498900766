import { mapMutations, mapGetters } from "vuex";

export const hasGlobalLoaderAccess = {
  computed: {
    ...mapGetters("ui", ["isLoading"]),
  },
  methods: {
    ...mapMutations("ui", ["loading", "loaded"]),
  },
};

export const hasSnackbarAccess = {
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
  },
};

export const showsSnackbarErrors = {
  mixins: [hasSnackbarAccess],

  methods: {
    displayErrorSnackbar(error, { prepend, location }) {
      console.log(...[location, error].filter(Boolean));

      const msg = error.replace(/graphql error: /gi, "");
      this.showSnackbar({
        text: [prepend, msg].filter(Boolean).join(": "),
      });
    },
  },
};
export const displaysErrorSnackbar = showsSnackbarErrors;

export const dialogButtonColor = {
  computed: {
    dialogButtonColor() {
      return this.$vuetify.breakpoint.smAndDown ? "white" : "accent";
    },
  },
};

export const blurActiveElement = {
  methods: {
    blurActiveElement() {
      return document.activeElement.blur();
    },
  },
};
