<script>
/**
 * Core component of dialog routes.
 * v-dialog without an activator.  Instead of closing, you go back
 */
import VxDialogBase from "./VxDialogBase";

export default {
  name: "VxDialogViewDesktop",

  extends: VxDialogBase,
  inheritAttrs: false,

  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      default: 600,
    },
    alignActionsRight: {
      type: Boolean,
      default: true,
    },
    vSize: {
      type: String,
      default: "medium",
    },
    hSize: {
      type: String,
      default: "medium",
    },
  },
  computed: {
    HSizeParsedValue() {
      switch (this.hSize) {
        case "huge":
          return "90vw";

        case "large":
          return "1100";

        case "medium":
          return "800";

        case "small":
          return "500";

        case "xsmall":
          return "400";
        default:
          return this.maxWidth;
      }
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="fullscreen"
    eager
    :max-width="'90vw'"
    :width="HSizeParsedValue"
    :persistent="persistent"
    scrollable
    :retain-focus="retainFocus"
  >
    <div
      :class="['desktop-dialog-content', 'size-v-' + vSize, 'size-h-' + hSize]"
    >
      <slot name="inner-dialog">
        <v-card
          v-bind="$attrs"
          :class="[
            'vx-dialog-view--card--desktop--content',
            'size-v-' + vSize,
            'size-h-' + hSize,
            'd-flex',
            'flex-column',
          ]"
        >
          <slot name="toolbar">
            <v-card-title class="pt-0 pl-0 pr-0">
              <v-app-bar
                flat
                short
                dark
                :color="errorToolbar ? 'error' : 'accent'"
              >
                <v-app-bar-nav-icon>
                  <v-btn v-if="!noBack" icon @click="dialog = false">
                    <v-icon>$back</v-icon>
                  </v-btn>
                </v-app-bar-nav-icon>
                <slot name="toolbar-title">
                  <v-toolbar-title
                    ><slot name="title">{{ title }}</slot></v-toolbar-title
                  >
                </slot>
                <template v-if="hasToolbarExtensionSlot()" #extension>
                  <slot name="toolbar-extension"></slot>
                </template>
              </v-app-bar>
            </v-card-title>
          </slot>
          <v-card-text>
            <slot></slot>
          </v-card-text>
          <v-card-actions class="mt-auto">
            <v-spacer v-if="alignActionsRight"></v-spacer>
            <slot name="actions"></slot>
          </v-card-actions>
        </v-card>
      </slot>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.v-card {
  height: 100%;
}

.v-card__text {
  overflow: auto;
  flex-grow: 1;
}

.desktop-dialog-content,
.vx-dialog-view--card--desktop--content {
  &.size-v-huge {
    height: 90vh;
    max-height: 90vh;
  }

  &.size-v-large {
    height: 760px;
    max-height: 90vh;
  }

  &.size-v-medium {
    height: 560px;
    max-height: 90vh;
  }

  &.size-v-small {
    height: 360px;
    max-height: 90vh;
  }

  &.size-h-huge {
    width: 90vw;
  }

  &.size-h-large {
    width: 1100px;
  }

  &.size-h-medium {
    width: 800px;
  }

  &.size-h-small {
    width: 500px;
  }

  &.size-h-xsmall {
    width: 400px;
  }
}
</style>
