<script>
/**
 * Core component of dialog routes.
 * v-dialog without an activator.  Instead of closing, you go back
 */
import VxDialogBase from "./VxDialogBase";

export default {
  name: "VxDialogViewMobile",

  extends: VxDialogBase,
  inheritAttrs: false,

  props: {
    alignActionsRight: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" scrollable fullscreen :retain-focus="retainFocus">
    <slot name="inner-dialog">
      <v-card class="vx-dialog-view--card--mobile fill-height">
        <slot name="toolbar">
          <v-card-title class="pt-0 pl-0 pr-0">
            <v-app-bar dark :color="errorToolbar ? 'error' : 'accent'">
              <v-app-bar-nav-icon>
                <v-btn v-if="!noBack" icon @click="dialog = false">
                  <v-icon>$back</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <slot name="toolbar-title">
                <v-toolbar-title class="mr-auto"
                  ><slot name="title">{{ title }}</slot></v-toolbar-title
                >
              </slot>
              <slot name="actions"></slot>
              <template v-if="hasToolbarExtensionSlot()" #extension>
                <slot name="toolbar-extension"></slot>
              </template>
            </v-app-bar>
          </v-card-title>
        </slot>

        <v-card-text>
          <slot></slot>
        </v-card-text>

        <div v-if="hasLargeActionsSlot()" class="large-actions-wrapper">
          <v-card-actions>
            <slot name="large-actions"> </slot>
          </v-card-actions>
        </div>
      </v-card>
    </slot>
  </v-dialog>
</template>

<style lang="scss">
.v-toolbar__title {
  text-overflow: ellipsis;
}

.vx-dialog-view--card--mobile {
  position: relative;
}

.large-actions-wrapper {
  border-top: 1px solid #000000;
}
</style>
