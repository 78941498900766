<script>
import VxDialogView from "@/components/vx/VxDialogView";
import {
  RELEASE_NOTE_QUERY,
  RELEASE_SEND_FEEDBACK_MUTATION,
} from "./SoftwareUpdateGql";

import { useNamespace } from "@/mixins/i18n";
import { handlesMutationErrors } from "@/mixins/graphql";

export default {
  components: { VxDialogView },
  mixins: [
    useNamespace("settings.softwareUpdate.feedback"),
    handlesMutationErrors,
  ],

  apollo: {
    release: {
      query: RELEASE_NOTE_QUERY,
      variables() {
        return {
          id: this.$route.params.releaseId,
        };
      },
    },
  },

  data: () => ({
    working: false,
    release: {},
    feedbackTypes: [
      { value: "feature", text: "Request a feature" },
      { value: "bug", text: "Report a bug" },
      { value: "other", text: "Other feedback" },
    ],
    feedbackType: undefined,
    body: "",
    contact: undefined,
  }),

  computed: {
    title() {
      return `Send feedback about v${this.release.version}`;
    },
    variables() {
      return {
        input: {
          id: this.$route.params.releaseId,
          feedbackType: this.feedbackType,
          body: this.body.trim(),
          contact: (this.contact && this.contact.trim()) || undefined,
        },
      };
    },
  },

  methods: {
    async onSendFeedbackClick() {
      this.working = true;

      try {
        const result = await this.$apollo.mutate({
          mutation: RELEASE_SEND_FEEDBACK_MUTATION,
          variables: this.variables,
        });
        this.throwMutationErrors({ result });
        this.showSnackbar({
          text: `Thank you for your feedback! 🤖`,
        });
        this.$router.go(-1);
      } catch (error) {
        this.catchMutationErrors({
          error,
          location: "SendFeedback/onSendFeedbackClick",
          prefix: "Error sending feedback",
        });
      }
      this.working = false;
    },
  },
};
</script>

<template>
  <VxDialogView :title="title">
    <p>{{ i18n("description") }}</p>
    <v-divider class="my-3" />
    <v-select
      v-model="feedbackType"
      class="shrink"
      label="Type"
      :items="feedbackTypes"
    />
    <v-textarea v-model="body" label="Feedback" />
    <v-text-field
      v-model="contact"
      type="email"
      label="Contact"
      hint="Add an email address in case we need to follow up"
    />
    <template #actions>
      <v-spacer />
      <v-btn
        text
        color="primary"
        :loading="working"
        @click="onSendFeedbackClick"
        >Send feedback</v-btn
      >
    </template>
  </VxDialogView>
</template>
