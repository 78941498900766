import { displaysErrorSnackbar } from "@/mixins/ui";
import get from "lodash/get";

export const handlesMutationErrors = {
  mixins: [displaysErrorSnackbar],

  methods: {
    /**
     *
     * @param {Object} throwParam - Throw param object
     * @param {Object} throwParam.result - The mutation result
     * @param {string} [throwParam.key=mutationResult] - The mutation field or alias
     */
    throwMutationErrors({ result, key = "mutationResult" }) {
      const errors = get(result, `data.${key}.errors`);
      if (errors && errors.length) {
        throw new Error(errors.join("; "));
      }
    },
    /**
     *
     * @param {Object} catchParam - Catch param object
     * @param {Error} catchParam.error - The error caught by catch block
     * @param {string} catchParam.location - The component & method name where this is happening
     * @param {string} catchParam.prefix - A friendly user-facing string prepended to the error
     */
    catchMutationErrors({ error, location, prefix }) {
      this.displayErrorSnackbar(error.message, { location, prefix });
    },
  },
};
