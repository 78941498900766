var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":_vm.fullscreen,"eager":"","max-width":'90vw',"width":_vm.HSizeParsedValue,"persistent":_vm.persistent,"scrollable":"","retain-focus":_vm.retainFocus},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{class:['desktop-dialog-content', 'size-v-' + _vm.vSize, 'size-h-' + _vm.hSize]},[_vm._t("inner-dialog",function(){return [_c('v-card',_vm._b({class:[
          'vx-dialog-view--card--desktop--content',
          'size-v-' + _vm.vSize,
          'size-h-' + _vm.hSize,
          'd-flex',
          'flex-column',
        ]},'v-card',_vm.$attrs,false),[_vm._t("toolbar",function(){return [_c('v-card-title',{staticClass:"pt-0 pl-0 pr-0"},[_c('v-app-bar',{attrs:{"flat":"","short":"","dark":"","color":_vm.errorToolbar ? 'error' : 'accent'},scopedSlots:_vm._u([(_vm.hasToolbarExtensionSlot())?{key:"extension",fn:function(){return [_vm._t("toolbar-extension")]},proxy:true}:null],null,true)},[_c('v-app-bar-nav-icon',[(!_vm.noBack)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("$back")])],1):_vm._e()],1),_vm._t("toolbar-title",function(){return [_c('v-toolbar-title',[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2)]})],2)],1)]}),_c('v-card-text',[_vm._t("default")],2),_c('v-card-actions',{staticClass:"mt-auto"},[(_vm.alignActionsRight)?_c('v-spacer'):_vm._e(),_vm._t("actions")],2)],2)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }