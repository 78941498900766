export const useNamespace = (namespace) => ({
  methods: {
    i18n(key, ...replace) {
      const val = this.$t(`${namespace}.${key}`);
      if (!val) return "";

      if (!replace.length) return val;

      return replace.reduce((str, replacement) => {
        return str.replace(/<#.*?#>/i, replacement);
      }, val);
    },
  },
});
