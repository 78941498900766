import { VDialog } from "vuetify/lib";

export default {
  extends: VDialog,
  props: {
    title: {
      type: String,
      default: "",
    },
    noBack: {
      type: Boolean,
      default: false,
    },
    retainFocus: {
      type: Boolean,
      default: true,
    },
    errorToolbar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    dialog(v) {
      if (v == false) {
        this.$router.go(-1);
      }
    },
  },
  methods: {
    hasToolbarExtensionSlot() {
      return (
        !!this.$scopedSlots["toolbar-extension"] &&
        !!this.$scopedSlots["toolbar-extension"]()
      );
    },
    hasLargeActionsSlot() {
      return (
        !!this.$scopedSlots["large-actions"] &&
        !!this.$scopedSlots["large-actions"]()
      );
    },
  },
  mounted() {
    this.dialog = true;
  },
};
